import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
  selector: 'hpm-two-label-toggle',
  templateUrl: './two-label-toggle.component.html',
  styleUrl: './two-label-toggle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwoLabelToggleComponent {
  @Input() firstLabel = 'UTILS.TWO_LABEL_TOGGLE.NO';
  @Input() secondLabel = 'UTILS.TWO_LABEL_TOGGLE.YES';
  @Input() checked = false;
  @Output() changeToggleButton: EventEmitter<MatSlideToggleChange> = new EventEmitter<MatSlideToggleChange>();

  onToggleChange($event: MatSlideToggleChange): void {
    this.checked = $event.checked;
    this.changeToggleButton.emit($event);
  }
}
