export interface User {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  role: UserRole;
}

export function getDefaultUser(): User {
  return {
    id: '',
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    role: UserRole.FIELD_SERVICE,
  };
}

export enum UserRole {
  FIELD_SERVICE = 'FIELD_SERVICE',
  OFFICE_SERVICE = 'OFFICE_SERVICE',
  RETAIL_PARTNER = 'RETAIL_PARTNER',
  ADMIN = 'ADMIN',
  MARKETING = 'MARKETING',
  CUSTOMER = 'CUSTOMER',
}
