import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {CxSearchBarComponent} from "@bbraun/cortex/search-bar";

@Component({
  selector: 'hpm-multi-search-select-dialog',
  templateUrl: './multi-search-select-dialog.component.html',
  styleUrl: './multi-search-select-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSearchSelectDialogComponent implements OnInit, AfterViewInit {
  @Input() label = '';
  @Input() itemList: string[] = [];
  @Input() selectedItems: string[] = [];
  @Output() selectedItemsChange = new EventEmitter<string[]>();
  @Output() selectionFinished = new EventEmitter<void>();
  allComplete = false;
  filteredList: string[] = [];

  @ViewChild('searchBar') searchBar: CxSearchBarComponent | undefined;

  ngOnInit(): void {
    this.filteredList = this.itemList;
    this.updateAllComplete();
  }

  ngAfterViewInit(): void {
    if (this.searchBar) {
      this.searchBar.focus();
    }
  }

  updateAllComplete(): void {
    const listEmpty = this.filteredList.length === 0;
    const allSelected = this.filteredList.every((item) =>
      this.isItemSelected(item),
    );
    this.allComplete = !listEmpty && allSelected;
  }

  partiallySelected(): boolean {
    const atLeastOneSelected = this.filteredList.some((item) =>
      this.isItemSelected(item),
    );
    return !this.allComplete && atLeastOneSelected;
  }

  setAll(completed: boolean): void {
    this.allComplete = completed;
    if (this.allComplete) {
      this.filteredList.forEach((item) => {
        if (!this.isItemSelected(item)) {
          this.selectedItems.push(item);
        }
      });
    } else {
      this.filteredList.forEach((item) => {
        this.unselectItem(item);
      });
    }
  }

  unselectItem(item: string): void {
    const itemIndex = this.selectedItems.indexOf(item);
    if (itemIndex > -1) {
      this.selectedItems.splice(itemIndex, 1);
    }
  }

  save(): void {
    this.selectionFinished.next();
  }

  isItemSelected(item: string): boolean {
    return this.selectedItems.includes(item);
  }

  toggleItem(item: string): void {
    if (this.isItemSelected(item)) {
      this.unselectItem(item);
    } else {
      this.selectedItems.push(item);
    }
    this.updateAllComplete();
  }

  filterItemList(searchString: string): void {
    this.filteredList = this.itemList.filter((item) =>
      item.toLowerCase().includes(searchString.toLowerCase()),
    );
    this.updateAllComplete();
  }
}
