<form [formGroup]="form">
  <mat-form-field (click)="openDialog()" (keyup.space)="openDialog()" class="multi-search-form-field">
    <mat-label>{{ label | translate }}</mat-label>
    <input #trigger="cdkOverlayOrigin"
           #formField
           formControlName="inputField"
           [value]="getDisplayValue()"
           cdkOverlayOrigin
           class="input"
           matInput
           [required]="multiFieldRequired"
           readonly>
    <mat-error>{{ 'ORDER_DETAIL.GENERAL.REQUIRED_FIELD_MISSING' | translate }}</mat-error>
    <svg aria-hidden="true" class="customDropdown" focusable="false" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M7 10l5 5 5-5z"/>
    </svg>
  </mat-form-field>

  <ng-template
    (backdropClick)="abortDialog()"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="opened"
    [cdkConnectedOverlayOrigin]="trigger"
    cdk-connected-overlay
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  >
    <hpm-multi-search-select-dialog
      (selectionFinished)="selectionFinished()"
      *ngIf="opened"
      [(selectedItems)]="selectedItems"
      [itemList]="itemList"
      [label]="label"
    ></hpm-multi-search-select-dialog>
  </ng-template>
</form>
