<cx-card [cardTitle]="label">
  <mat-divider class="divider"></mat-divider>

  <cx-search-bar #searchBar (search)="filterItemList($event)"
                 [placeholder]="label"
  ></cx-search-bar>

  <mat-checkbox
    (change)="setAll($event.checked)"
    [checked]="allComplete"
    [indeterminate]="partiallySelected()"
  >
    {{ "UTILS.MULTIPLE_SELECT.SELECT_ALL" | translate }}
  </mat-checkbox>

  <mat-divider></mat-divider>

  <div class="itemList">
    <mat-checkbox
      (change)="toggleItem(item)"
      *ngFor="let item of filteredList"
      [checked]="isItemSelected(item)"
    >
      {{ item }}
    </mat-checkbox>
  </div>
  <cx-card-footer>
    <button (click)="save()" color="accent" mat-button mat-flat-button>
      {{ "UTILS.MULTIPLE_SELECT.SAVE" | translate }}
    </button>
  </cx-card-footer>
</cx-card>
